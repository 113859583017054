"use client";

import React, { type PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import { type Widget } from "../../model/widget-model";
import { type UpdateWidgetMutationType, type UpdateWidgetType, useUpdateWidget } from "./useUpdateWidget";

// Define context
interface WidgetContextType<T extends Widget> {
  widgetKey: string;
  widget: T;
  setWidget: (widget: Widget) => void;
  editedWidget: T;
  setEditedWidget: (widget: Widget) => void;
  isDirty: boolean;
  updateWidgetMutation: UpdateWidgetMutationType;
  updateWidget: UpdateWidgetType;
}
// Create the context with default values
const WidgetContext = createContext<WidgetContextType<Widget> | undefined>(undefined);
export interface WidgetProviderProps<T extends Widget> extends PropsWithChildren {
  widgetKey: string;
  widget: T;
}

// Provider component
export function WidgetProvider<T extends Widget>({
  children,
  ...props
}: WidgetProviderProps<T>) {
  const [widget, setWidget] = useState<T>(props.widget);
  const [editedWidget, setEditedWidget] = useState<T>(props.widget);
  const isDirty = useMemo(() => {
    return JSON.stringify(widget) !== JSON.stringify(editedWidget);
  }, [widget, editedWidget]);
  const {
    updateWidget,
    updateWidgetMutation
  } = useUpdateWidget();
  const __INTERNAL__updateWidget: UpdateWidgetType = async props => {
    const result = await updateWidget(props);
    if (!result) {
      return null;
    }
    setWidget(result as T);
    setEditedWidget(result as T);
    return result;
  };
  const __INTERNAL__setWidget = (widget: Widget) => {
    setWidget(widget as T);
  };
  const __INTERNAL_setEditedWidget = (widget: Widget) => {
    setEditedWidget(widget as T);
  };
  return <WidgetContext.Provider value={{
    widgetKey: props.widgetKey,
    widget,
    setWidget: __INTERNAL__setWidget,
    editedWidget,
    setEditedWidget: __INTERNAL_setEditedWidget,
    isDirty,
    updateWidgetMutation,
    updateWidget: __INTERNAL__updateWidget
  }} data-sentry-element="unknown" data-sentry-component="WidgetProvider" data-sentry-source-file="widget-provider.tsx">
      {children}
    </WidgetContext.Provider>;
}

// Custom hook to use WidgetContext
export function useWidget<T extends Widget>(): WidgetContextType<T> {
  const context = useContext(WidgetContext);
  if (context === undefined) {
    throw new Error("useWidget must be used within a WidgetProvider");
  }
  return context as WidgetContextType<T>;
}