"use client";

import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { api } from "~/trpc/react";
import { type Widget } from "../../model/widget-model";

// Define the types for creating an widget
type CreateWidgetProps = Omit<Widget, "id" | "organizationId" | "createdAt" | "updatedAt">;
export type CreateWidgetMutationType = ReturnType<typeof api.widget.createWidget.useMutation>;
export type CreateWidgetType = (props?: CreateWidgetProps | undefined) => Promise<Widget | null>;
export const useCreateWidget = () => {
  const router = useRouter();
  const createWidgetMutation = api.widget.createWidget.useMutation();
  const createWidget = async (props: CreateWidgetProps) => {
    const result = await createWidgetMutation.mutateAsync({
      ...props
    });
    if (!result) {
      toast.error("Leider ist ein Fehler aufgetreten. Das Widget konnte nicht erstellt werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      return null;
    }
    router.refresh();
    return result;
  };
  return {
    createWidgetMutation,
    createWidget
  };
};